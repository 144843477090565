export default [
    {
        path: '/settings/global',
        component: () => import('@/views/dashboard/settings/SettingsGlobal.vue'),
        meta: {
            service: 'settings',
            smallMenu: true,
            active: 'settings',
        },
        children: [
            {
                path: 'general',
                name: 'settings',
                component: () => import('@/components/settings/SettingsContent.vue'),
                props: true,
            },
            {
                path: 'terms',
                name: 'settings.terms',
                component: () => import('@/components/settings/content/GlobalTermsConditions.vue'),
                props: true,
            },
            {
                path: 'privacy',
                name: 'settings.privacy',
                component: () => import('@/components/settings/content/GlobalPrivacyPolicy.vue'),
                props: true,
            }
        ],
    },
]