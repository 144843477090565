export default [
    {
        path: '/services/court',
        name: 'services.court',
        component: () => import('@/views/dashboard/court/CourtSummary.vue'),
        meta: { 
            service: 'court',
            smallMenu: true,
            active: 'court'
        },
    },

    // LEADS INDEX
    {
        path: '/services/court/leads',
        name: 'services.court.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: { 
            service: 'court',
            type: 'lead',
            smallMenu: true,
            active: 'court'
        },
    },
    // LEADS SINGLE
    {
        path: '/services/court/leads/view/:reference?',
        name: 'services.court.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'court',
            type: 'lead',
            smallMenu: true,
            active: 'court',
        }
    },

    // QUOTES INDEX
    {
        path: '/services/court/quotes',
        name: 'services.court.quotes',
        component: () => import('@/views/dashboard/court/CourtQuotesIndex.vue'),
        meta: { 
            service: 'court',
            type: 'quote',
            smallMenu: true,
            active: 'court'
        },
    },
    // QUOTES SINGLE
    {
        path: '/services/court/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'court',
            type: 'quote',
            smallMenu: true,
            active: 'court'
        },
        children: [
            {
                path: '',
                name: 'services.court.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.court.quote.view.summary',
                component: () => import('@/components/dashboard/court/CourtQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.court.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.court.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },

    // // MATTERS INDEX
    // {
    //     path: '/services/court/matters',
    //     name: 'services.court.matters',
    //     component: () => import('@/views/dashboard/court/CourtMattersIndex.vue'),
    //     meta: { 
    //         service: 'court',
    //         type: 'matter',
    //         smallMenu: true,
    //         active: 'court'
    //     },
    // },
]