export default [
    {
        path: '/mailing',
        name: 'mailing',
        component: () => import('@/views/dashboard/mailing/MailingSummary.vue'),
        meta: { 
            service: 'mailing',
            smallMenu: true,
            active: 'mailing',
        },
    },
]