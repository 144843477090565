export default [
    {
        path: '/services/lpa',
        name: 'services.lpa',
        component: () => import('@/views/dashboard/lpa/LpaSummary.vue'),
        meta: { 
            service: 'lpa',
            smallMenu: true,
            active: 'lpa'
        },
    },

    // LEADS INDEX
    {
        path: '/services/lpa/leads',
        name: 'services.lpa.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: { 
            service: 'lpa',
            type: 'lead',
            smallMenu: true,
            active: 'lpa'
        },
    },
    // LEADS SINGLE
    {
        path: '/services/lpa/leads/view/:reference?',
        name: 'services.lpa.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'lpa',
            type: 'lead',
            smallMenu: true,
            active: 'lpa',
        }
    },

    // QUOTES INDEX
    {
        path: '/services/lpa/quotes',
        name: 'services.lpa.quotes',
        component: () => import('@/views/dashboard/lpa/LpaQuotesIndex.vue'),
        meta: { 
            service: 'lpa',
            type: 'quote',
            smallMenu: true,
            active: 'lpa'
        },
    },
    // QUOTES SINGLE
    {
        path: '/services/lpa/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'lpa',
            type: 'quote',
            smallMenu: true,
            active: 'lpa'
        },
        children: [
            {
                path: '',
                name: 'services.lpa.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.lpa.quote.view.summary',
                component: () => import('@/components/dashboard/lpa/LpaQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.lpa.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.lpa.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },

    // // MATTERS INDEX
    // {
    //     path: '/services/lpa/matters',
    //     name: 'services.lpa.matters',
    //     component: () => import('@/views/dashboard/lpa/LpaMattersIndex.vue'),
    //     meta: { 
    //         service: 'lpa',
    //         type: 'matter',
    //         smallMenu: true,
    //         active: 'lpa'
    //     },
    // },
];