<template>
    <div class="rounded relative border p-4 pb-16 mb-6">

        <block-header heading="Kew Law"></block-header>

        <input-wrapper label="Branch">
            <ion-select
                placeholder="Select Branch"
                @ionChange="filterKlUsers()"
                :value="pageData.data.branch_id"
                v-model="pageData.data.branch_id" 
            >
                <ion-select-option value="">None</ion-select-option>
                <ion-select-option 
                    v-for="(branch,index) in pageData.branches"
                    :key="index"
                    :value="branch.id"
                >{{branch.name}}</ion-select-option>
            </ion-select>
        </input-wrapper>

        <input-wrapper label="Assigned User" :error="pageData.errors.assigned_user_id">
            <ion-select
                :disabled="!pageData.data.branch_id"
                placeholder="Select User"
                :value="pageData.data.assigned_user_id"
                v-model="pageData.data.assigned_user_id" 
            >
                <ion-select-option value="">None</ion-select-option>
                <ion-select-option 
                    v-for="(user,index) in klUsers"
                    :key="index"
                    :value="user.id"
                >{{user.full_name}}</ion-select-option>
            </ion-select>
        </input-wrapper>

    </div>
</template>

<script setup lang="ts">
import { inject, onMounted, ref, watch } from 'vue'
import { 
    IonSelect,
    IonSelectOption,
} from '@ionic/vue'
import BlockHeader from '@/components/layout/BlockHeader.vue'
import { User } from '@/interfaces/types/UserInterfaces';

const emit = defineEmits(
    [
        'changeMade',
    ]
)

const pageData = inject('data') as any;

const klUsers = ref<User[]>([]);

function filterKlUsers() {
    klUsers.value = pageData.kl_users.filter((user: User) => {
        return user.branches.includes(pageData.data.branch_id) || user.type === 'admin';
    });
}

watch(() => [pageData.data.branch_id,
            pageData.data.assigned_user_id], () => {
            emit('changeMade');
});

onMounted(() => {
    if(pageData.data.branch_id) {
        filterKlUsers();
    }
});

</script>
