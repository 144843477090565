export default function() {
    console.log('loadScript');
    

    const addScript = (url: string, id: string, callback: any) => {
        if(scriptExists(id)) return;
        const script = document.createElement('script');
        script.setAttribute('src',url);
        script.id = id;
        script.async = true;
        script.onload = () => {
            callback();
        }
        document.body.appendChild(script);
    }

    const scriptExists = (id: string) => {
        const script = document.getElementById(id);
        if(script) return true;
        return false;
    }

    return {
        addScript
    }
}