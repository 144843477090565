export default [
    {
        path: '/services/employment',
        name: 'services.employment',
        component: () => import('@/views/dashboard/employment/EmploymentSummary.vue'),
        meta: { 
            service: 'employment',
            smallMenu: true,
            active: 'employment'
        },
    },

    {
        path: '/services/employment-settlement',
        name: 'services.employment-settlement',
        component: () => import('@/views/dashboard/employment-settlement/EmploymentSettlementSummary.vue'),
        meta: { 
            service: 'employment-settlement',
            smallMenu: true,
            active: 'employment'
        },
    },

    // LEADS INDEX
    {
        path: '/services/employment/leads',
        name: 'services.employment.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: { 
            service: 'employment',
            type: 'lead',
            smallMenu: true,
            active: 'employment'
        },
    },

    // LEADS INDEX
    {
        path: '/services/employment-settlement/leads',
        name: 'services.employment-settlement.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: { 
            service: 'employment-settlement',
            type: 'lead',
            smallMenu: true,
            active: 'employment'
        },
    },
    // LEADS SINGLE
    {
        path: '/services/employment/leads/view/:reference?',
        name: 'services.employment.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'employment',
            type: 'lead',
            smallMenu: true,
            active: 'employment',
        }
    },

    // LEADS SINGLE
    {
        path: '/services/employment-settlement/leads/view/:reference?',
        name: 'services.employment-settlement.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'employment-settlement',
            type: 'lead',
            smallMenu: true,
            active: 'employment',
        }
    },

    // QUOTES INDEX
    {
        path: '/services/employment/quotes',
        name: 'services.employment.quotes',
        component: () => import('@/views/dashboard/employment/EmploymentQuotesIndex.vue'),
        meta: { 
            service: 'employment',
            type: 'quote',
            smallMenu: true,
            active: 'employment'
        },
    },

    // QUOTES INDEX
    {
        path: '/services/employment-settlement/quotes',
        name: 'services.employment-settlement.quotes',
        component: () => import('@/views/dashboard/employment-settlement/EmploymentSettlementQuotesIndex.vue'),
        meta: { 
            service: 'employment-settlement',
            type: 'quote',
            smallMenu: true,
            active: 'employment'
        },
    },
    // QUOTES SINGLE
    {
        path: '/services/employment/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'employment',
            type: 'quote',
            smallMenu: true,
            active: 'employment'
        },
        children: [
            {
                path: '',
                name: 'services.employment.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.employment.quote.view.summary',
                component: () => import('@/components/dashboard/employment/EmploymentQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.employment.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.employment.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },

    // // MATTERS INDEX
    // {
    //     path: '/services/employment/matters',
    //     name: 'services.employment.matters',
    //     component: () => import('@/views/dashboard/employment/EmploymentMattersIndex.vue'),
    //     meta: { 
    //         service: 'employment',
    //         type: 'matter',
    //         smallMenu: true,
    //         active: 'employment'
    //     },
    // },

    // QUOTES SINGLE
    {
        path: '/services/employment-settlement/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'employment-settlement',
            type: 'quote',
            smallMenu: true,
            active: 'employment'
        },
        children: [
            {
                path: '',
                name: 'services.employment-settlement.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.employment-settlement.quote.view.summary',
                component: () => import('@/components/dashboard/employment-settlement/EmploymentSettlementQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.employment-settlement.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.employment-settlement.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },

    // // MATTERS INDEX
    // {
    //     path: '/services/employment-settlement/matters',
    //     name: 'services.employment-settlement.matters',
    //     component: () => import('@/views/dashboard/employment-settlement/EmploymentSettlementMattersIndex.vue'),
    //     meta: { 
    //         service: 'employment',
    //         type: 'matter',
    //         smallMenu: true,
    //         active: 'employment'
    //     },
    // },
]