<template>
    <div class="h-full border-r overflow-y-scroll">

        <sub-menu
            :links="links"
            header="Employment Law"
        ></sub-menu>

        <sub-menu
            :links="settlementLinks"
            header="Settlement Agreement"
        ></sub-menu>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SubMenu from '@/components/menu/SubMenu.vue'
import menus from '@/utils/menus'

export default defineComponent({
    name: 'EmploymentSidebar',
    components: {
        SubMenu
    },
    setup() {
        const { employmentSidebarLinks } = menus();
        const { employmentSettlementSidebarLinks } = menus();

        return {
            links: employmentSidebarLinks,
            settlementLinks: employmentSettlementSidebarLinks
        }
    }  
})
</script>