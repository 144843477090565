export default [
    // LEADS INDEX
    {
        path: '/conveyancing/residential/sales/leads',
        name: 'residential.sales.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: {
            service: 'res-sale',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },
    // LEADS SINGLE
    {
        path: '/conveyancing/residential/sales/leads/view/:reference?',
        name: 'services.res-sale.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'res-sale',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },

    // QUOTES INDEX
    {
        path: '/conveyancing/residential/sales/quotes',
        name: 'residential.sales.quotes',
        component: () => import('@/views/dashboard/residential/ResidentialSalesQuotes.vue'),
        meta: {
            service: 'res-sale',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
    },
    // QUOTES SINGLE
    {
        path: '/conveyancing/residential/sales/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: {
            service: 'res-sale',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
        children: [
            {
                path: 'view',
                name: 'services.res-sale.quotes.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.res-sale.quotes.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'documents',
                name: 'services.res-sale.quotes.view.documents',
                component: () => import('@/components/dashboard/misc/QuoteMatterDocuments.vue'),
                props: true,
            },
            {
                path: 'submission',
                name: 'services.res-sale.quotes.view.submission',
                component: () => import('@/components/dashboard/misc/QuoteMatterOriginalSubmission.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.res-sale.quotes.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ]
    },

    // MATTERS INDEX
    {
        path: '/conveyancing/residential/sales/matters',
        name: 'residential.sales.matters',
        component: () => import('@/views/dashboard/residential/ResidentialSalesMatters.vue'),
        meta: {
            service: 'res-sale',
            type: 'matter',
            smallMenu: true,
            active: 'residential'
        },
    },
     // MATTERS SINGLE
     {
        path: '/conveyancing/residential/sales/matters/view/:reference?',
        component: () => import('@/views/layouts/SingleMatterWrapper.vue'),
        props: true,
        meta: {
            service: 'res-sale',
            type: 'matter',
            smallMenu: true,
            active: 'residential'
        },
        children: [
            {
                path: 'view',
                name: 'residential.sales.matters.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'tasks',
                name: 'residential.sales.matters.view.tasks',
                component: () => import('@/views/dashboard/residential/ResidentialSaleSingleTasks.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'residential.sales.matters.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'documents',
                name: 'residential.sales.matters.view.documents',
                component: () => import('@/components/dashboard/misc/QuoteMatterDocuments.vue'),
                props: true,
            },
            {
                path: 'submission',
                name: 'residential.sales.matters.view.submission',
                component: () => import('@/components/dashboard/misc/QuoteMatterOriginalSubmission.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'residential.sales.matters.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ]
    },
];