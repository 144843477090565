export default [
    {
        path: '/branches',
        name: 'branches',
        component: () => import('@/views/dashboard/branches/BranchSummary.vue'),
        meta: { 
            service: 'branches',
            smallMenu: true,
            active: 'branches',
        },
    },

    {
        path: '/branches/introducers',
        name: 'branches.introducers',
        component: () => import('@/views/dashboard/branches/IntroducerBranchIndex.vue'),
        meta: { 
            service: 'introducers',
            smallMenu: true,
            active: 'branches',
        },
    },

    {
        path: '/branches/kew-law',
        name: 'branches.kew-law',
        component: () => import('@/views/dashboard/branches/KewLawBranchIndex.vue'),
        meta: { 
            service: 'branches',
            smallMenu: true,
            active: 'branches',
        },
    },

    // out of use for now, branch edits are happening in the create branch modal
    // {
    //     path: '/branches/edit/:id?',
    //     name: 'branches.edit',
    //     component: () => import('@/views/dashboard/branches/BranchesEdit.vue'),
    //     meta: { 
    //         service: 'branches',
    //         smallMenu: true,
    //         active: 'branches',
    //     },
    // },
]