export default [
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/dashboard/users/UserSummary.vue'),
        meta: { 
            service: 'users',
            smallMenu: true,
            active: 'users',
        },
    },
    {
        path: '/users/clients',
        name: 'users.clients',
        component: () => import('@/views/dashboard/users/UserClientIndex.vue'),
        meta: { 
            service: 'clients',
            smallMenu: true,
            active: 'users',
        },
    },
    {
        path: '/users/introducers',
        name: 'users.introducers',
        component: () => import('@/views/dashboard/users/UserIntroducerIndex.vue'),
        meta: { 
            service: 'introducers',
            smallMenu: true,
            active: 'users',
        },
    },
    {
        path: '/users/employees',
        name: 'users.employees',
        component: () => import('@/views/dashboard/users/UserEmployeeIndex.vue'),
        meta: { 
            service: 'employees',
            smallMenu: true,
            active: 'users',
        },
    },
    {
        path: '/users/employees/edit/:userid?',
        component: () => import('@/views/dashboard/users/UserEmployeeEdit.vue'),
        meta: { 
            service: 'employees',
            type: 'user',
            smallMenu: true,
            active: 'users',
        },
        props: true,
        children: [
            {
                path: '',
                name: 'users.employees.edit',
                component: () => import('@/components/dashboard/users/EmployeeDetails.vue')
            },
            {
                path: 'permissions',
                name: 'users.employees.edit.permissions',
                component: () => import('@/components/dashboard/users/EmployeePermissions.vue')
            },
            {
                path: 'activity',
                name: 'users.employees.edit.activity',
                component: () => import('@/components/dashboard/users/EmployeeActivity.vue')
            },
        ]
    },
    {
        path: '/users/admin',
        name: 'users.admin',
        component: () => import('@/views/dashboard/users/UserAdminIndex.vue'),
        meta: { 
            service: 'admin',
            smallMenu: true,
            active: 'users',
        },
    },
]