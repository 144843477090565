<template>
    <div class="relative h-auto">
        <h6 v-if="label" position="stacked" class="text-lg block">{{label}}</h6>
        <h3 v-if="header3" position="stacked" class="mb-3">{{header3}}</h3>
        <ion-item lines="none" class="w-full mt-2 mb-5" :class="{'currency-input': currency}">
            <slot></slot>
        </ion-item>
        <span id="validation_error" v-if="Object.keys(error).length" class="absolute -bottom-6 kl-red">{{ error[0] }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    IonItem,
} from '@ionic/vue';

export default defineComponent({
    name: 'InputLabel',
    components: {
        IonItem,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        header3: {
            type: String,
            default: '',
        },
        currency: {
            type: Boolean,
            default: false,
        },
        error:{
            type: Object,
            default: () => {return {}},
        }
    }
})
</script>
