import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    disabled: _ctx.disabled || _ctx.saving,
    size: _ctx.size,
    fill: "clear",
    class: "inline-flex align-middle align-items-center justify-center font-medium focus:outline-none border-2"
  }, {
    default: _withCtx(() => [
      (_ctx.saving)
        ? (_openBlock(), _createBlock(_component_fa_icon, {
            key: 0,
            icon: ['far', 'spinner-third'],
            size: "2x",
            spin: ""
          }))
        : _createCommentVNode("", true),
      (!_ctx.saving)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["disabled", "size"]))
}