export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/LoginPage.vue')
    },
    {
        path: '/forgotten-password',
        name: 'forgotten-password',
        component: () => import('@/views/auth/ForgottenPassword.vue')
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/views/auth/PasswordReset.vue')
    },
]