export default [
    // LEADS INDEX
    {
        path: '/conveyancing/residential/res-sale-purchase/leads',
        name: 'services.res-sale-purchase.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: {
            service: 'res-sale-purchase',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },
    // LEADS SINGLE
    {
        path: '/conveyancing/residential/res-sale-purchase/leads/view/:reference?',
        name: 'services.res-sale-purchase.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'res-sale-purchase',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },
    
];