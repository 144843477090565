import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import authRoutes from './authRoutes';
import branchManagementRoutes from './branchManagementRoutes';
import commercialConveyancingRoutes from './commercialConveyancingRoutes';
import dashboardRoutes from './dashboardRoutes';
import disputesAndLitigationRoutes from './disputesAndLitigationRoutes';
import employmentRoutes from './employmentRoutes';
import familyRoutes from './familyRoutes';
import generalSettingsRoutes from './generalSettingsRoutes';
import lpaRoutes from './lpaRoutes';
import mailingRoutes from './mailingRoutes';
import probateRoutes from './probateRoutes';
import residentialPurchaseRoutes from './residentialPurchaseRoutes';
import residentialRemortgageRoutes from './residentialRemortgageRoutes';
import residentialSalesRoutes from './residentialSalesRoutes';
import residentialTransferRoutes from './residentialTransferRoutes';
import residentialSalePurchaseRoutes from './residentialSalePurchaseRoutes';
import serviceSettingsRoutes from './serviceSettingsRoutes';
import taskSettingsRoutes from './taskSettingsRoutes';
import userManagementRoutes from './userManagementRoutes';
import willRoutes from './willRoutes';
import courtRoutes from './courtRoutes';
import commercialAndCorporateRoutes from './commercialAndCorporateRoutes';
import otherServicesRoutes from './otherServicesRoutes';
import residentialEquityRoutes from './residentialEquityRoutes';
import residentialLegalAdviceRoutes from './residentialLegalAdviceRoutes';
import residentialLeaseRoutes from './residentialLeaseRoutes';
import residentialHtbRoutes from './residentialHtbRoutes';
import residentialGeneralRoutes from './residentialGeneralRoutes';
import companyManagementRoutes from './companyManagementRoutes';

// router
const routes: Array<RouteRecordRaw> = [
    // LOGIN AND AUTH
    ...authRoutes,

    // DASHBOARD AND MISCELLANEOUS
    ...dashboardRoutes,
    
    // SERVICES
    ...residentialPurchaseRoutes,
    ...residentialSalesRoutes,
    ...residentialRemortgageRoutes,
    ...residentialTransferRoutes,
    ...residentialSalePurchaseRoutes,
    ...residentialEquityRoutes,
    ...residentialLegalAdviceRoutes,
    ...residentialLeaseRoutes,
    ...residentialHtbRoutes,
    ...residentialGeneralRoutes,
    ...commercialConveyancingRoutes,
    ...willRoutes,
    ...lpaRoutes,
    ...probateRoutes,
    ...employmentRoutes,
    ...disputesAndLitigationRoutes,
    ...familyRoutes,
    ...courtRoutes,
    ...commercialAndCorporateRoutes,
    ...otherServicesRoutes,
    
    // SETTINGS
    ...userManagementRoutes,
    ...branchManagementRoutes,
    ...companyManagementRoutes,
    ...mailingRoutes,

    ...generalSettingsRoutes,
    ...taskSettingsRoutes,
    ...serviceSettingsRoutes,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
