import { useQuery } from "@tanstack/vue-query";
import axios from "axios";

export default function getNotesQuery(service: string, type:string, reference: string) {

  return useQuery({
    queryFn: (): Promise<any> => axios.get("/api/services/"+service+"/notes/"+type+"/"+reference).then((res) => res.data),
    queryKey: ["notes", service, reference, type],
    enabled: !!service && !!reference && !!type,
  });

}