import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded relative border p-4 pb-16 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_block_header = _resolveComponent("block-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_input_wrapper = _resolveComponent("input-wrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_block_header, { heading: "Quote Details" }),
    _createVNode(_component_input_wrapper, { label: "First name" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_input, {
          modelValue: _ctx.pageData.data.first_name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageData.data.first_name) = $event)),
          size: "100%",
          onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changeMade')))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_input_wrapper, { label: "Surname" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_input, {
          modelValue: _ctx.pageData.data.surname,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageData.data.surname) = $event)),
          size: "100%",
          onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('changeMade')))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_input_wrapper, { label: "Email" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_input, {
          modelValue: _ctx.pageData.data.email,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageData.data.email) = $event)),
          size: "100%",
          onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('changeMade')))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_input_wrapper, { label: "Phone" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_input, {
          modelValue: _ctx.pageData.data.phone,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pageData.data.phone) = $event)),
          size: "100%",
          onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('changeMade')))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}