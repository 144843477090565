<template>
    <div class="w-full flex flex-row flex-nowrap py-3 text-sm border-b">
        <div class="w-1/3">
            {{formatLabel(label)}}
        </div>
        <div class="w-2/3 font-bold">
            <template v-if="typeof value === 'string' || typeof value === 'number'">{{value}}</template>
            <template v-if="typeof value === 'object'">
                <ul>
                    <li 
                        v-for="(item, key) in value" 
                        :key="key" 
                        v-html="formatItem(item)"
                    >
                    </li>
                </ul>
            </template>
            <template v-if="typeof value === 'array'">
                <ul>
                    <li v-for="(item, index) in value" :key="index">
                        {{item.name}}
                    </li>
                </ul>
            </template>
            <template v-if="typeof value === 'boolean'">{{ (value == true ? 'yes' : 'no') }}</template>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'KeyValueRow',
    props: {
        label: {
            type: String,
            default: '-'
        },
        value: {
            type: [String, Object, Array, Number, Boolean],
            default: '-',
        },
    },
    setup() {
        const formatLabel = (label: string) => {
            label = label.charAt(0).toUpperCase() + label.slice(1);
            return label.replace(/_/g, ' ');
        }
        const formatItem = (item: string | object | number | boolean): string | number => {
            if (typeof item === 'string' || typeof item === 'number') {
                return item;
            }
            if (typeof item === 'object') {
                let html = '<ul class="mb-2 border-b pb-2">';
                for (const [key, value] of Object.entries(item)) {
                    html+= `<li>${key}: ${value}</li>`;
                }
                return html + '</ul>';
            }
            if (typeof item === 'boolean') {
                return item ? 'yes' : 'no';
            }
            return '-';
        }

        return {
            formatLabel,
            formatItem,
        }
    },
})
</script>
