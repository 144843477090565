<template>
    <div class="notes rounded border px-4 pb-6 mb-6">
        <h2 class="border-b flex-grow w-full pb-3 relative">
            Notes
        </h2>

        <div 
            class="overflow-y-scroll max-h-60"
            ref="noteBlock"
        >
            <div 
                v-for="(note, index) in notes" :key="index"
                class="rounded bg-gray-100 px-2 pt-1 pb-3 mb-2"
            >
                <span class="text-xs font-bold">{{note.author}} - {{note.created_at}}</span>
                <p>{{note.content}}</p>
            </div>
        </div>
        

        <ion-item v-if="noteInputActive" lines="none" class="w-full">
            <ion-input type="text" v-model="content" placeholder="Note Content (min 10 characters)"></ion-input>
        </ion-item>

        <div class="text-right" v-if="authRole('edit-'+getPermissionsFromService(service))">
            <ion-button v-if="!noteInputActive" @click.prevent="showNoteInputBox">New Note</ion-button>
            <ion-button 
                v-if="noteInputActive" 
                :disabled="content.length < 10 || savingNote"
                @click.prevent="createNote"
            >Add Note</ion-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import {
    IonItem,
    IonInput,
    IonButton
} from '@ionic/vue';
import { AxiosError, AxiosResponse } from 'axios';
import { injectStrict } from '@/utils/injectTyped';
import { AxiosKey } from '@/symbols';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import serviceHelpers from '@/utils/serviceHelpers';
import getNotesQuery from '@/queries/getNotesQuery';

const store = useStore();
const route = useRoute();

const authRole = computed(() => store.getters.authRole);
const service = computed(() => route.meta.service as string);
const matterType = computed(() => route.meta.type as string);
const reference = computed(() => route.params.reference as string);

const { getPermissionsFromService } = serviceHelpers();

const { data: notes, refetch } = getNotesQuery(service.value, matterType.value, reference.value);

const axios = injectStrict(AxiosKey);

const content = ref('');
const savingNote = ref(false);
const noteInputActive = ref(false);
const createNote = () => {
    savingNote.value = true;
    axios.post('/api/services/'+route.meta.service+'/notes/create/'+route.meta.type+'/'+route.params.reference, {
        content: content.value
    })
    .then( () => {
        refetch();
        savingNote.value = false;
        noteInputActive.value = false;
        content.value = '';
        scrollToElement();
    }).catch( (e: AxiosError) => {
        console.log(e);
        savingNote.value = false;
    });
}

const showNoteInputBox = () => {
    noteInputActive.value = true;
}

const noteBlock = ref(null) as any;
const scrollToElement = (): void => {
    nextTick(() => {
        if (noteBlock.value) {
            noteBlock.value.scrollTop = noteBlock.value.scrollHeight + 120;
        }                
    });
    
}

</script>
