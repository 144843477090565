
<template>
    <div class="rounded relative border p-4 pb-16 mb-6">

        <block-header heading="Lead Form"></block-header>

        <!-- <input-wrapper 
            v-for="(item,index) in pageData.data.form"
            :key="index"
            :label="item.label">
            <ion-input v-if="isString(item.value)" v-model="item.value" size="100%" @IonChange="$emit('changeMade')"></ion-input>
        </input-wrapper> -->

        <key-value-row
                v-for="(item, index) in pageData.data.form"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></key-value-row>

    </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue'
import { 
    IonInput,
} from '@ionic/vue'
import BlockHeader from '@/components/layout/BlockHeader.vue'
import KeyValueRow from '@/components/layout/KeyValueRow.vue'

export default defineComponent({
    name: 'LeadFormBlock',
    components: {
        BlockHeader,
        // IonInput,
        KeyValueRow,
    },
    setup() {
        const pageData = inject('data') as any;

        const isString = (value: any) => typeof value === 'string' || value instanceof String;

        return {
            pageData,
            isString,
        }
    },
})
</script>
