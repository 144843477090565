<template>
    <div 
        v-if="prefix.length > 0"
        start
        class="prefix pr-3 border-r absolute top-0 left-0 bottom-0 w-6 flex justify-center items-center text-sm font-bold transition"
    >
        <span>{{prefix}}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InputPrefix',
    props: {
        prefix: {
            type: String,
            default: '£'
        }
    }
})
</script>
