export default [
    {
        path: '/settings/tasks',
        name: 'settings.tasks',
        component: () => import('@/components/settings/tasks/SettingsAllTasks.vue'),
        meta: {
            service: 'settings',
            smallMenu: true,
            active: 'settings',
        }
    },
    {
        path: '/settings/tasks/edit/:task',
        name: 'settings.tasks.single',
        component: () => import('@/components/settings/tasks/SettingsSingleTask.vue'),
        meta: {
            service: 'settings',
            smallMenu: true,
            active: 'settings',
        }
    },
]