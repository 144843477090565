export default [
    // BASE DASHBOARD
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/DashboardHome.vue'),
        meta: {
            service: 'home',
            active: 'home',
        }
    },
    // RESIDENTIAL DASHBOARD
    {
        path: '/conveyancing/residential',
        name: 'residential',
        component: () => import('@/views/dashboard/residential/ResidentialSummary.vue'),
        meta: { 
            service: 'res',
            smallMenu: true, 
            active: 'residential',
        },
    },
];