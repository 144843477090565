export default [
    {
        path: '/conveyancing/commercial',
        name: 'services.commercial',
        component: () => import('@/views/dashboard/commercial/CommercialSummary.vue'),
        meta: { 
            service: 'commercial',
            smallMenu: true,
            active: 'commercial'
        },
    },

    // LEADS INDEX
    {
        path: '/services/commercial/leads',
        name: 'services.commercial.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: { 
            service: 'commercial',
            type: 'lead',
            smallMenu: true,
            active: 'commercial'
        },
    },
    // LEADS SINGLE
    {
        path: '/services/commercial/leads/view/:reference?',
        name: 'services.commercial.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'commercial',
            type: 'lead',
            smallMenu: true,
            active: 'commercial',
        }
    },

    // QUOTES INDEX
    {
        path: '/services/commercial/quotes',
        name: 'services.commercial.quotes',
        component: () => import('@/views/dashboard/commercial/CommercialQuotesIndex.vue'),
        meta: { 
            service: 'commercial',
            type: 'quote',
            smallMenu: true,
            active: 'commercial'
        },
    },
    // QUOTES SINGLE
    {
        path: '/services/commercial/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'commercial',
            type: 'quote',
            smallMenu: true,
            active: 'commercial'
        },
        children: [
            {
                path: '',
                name: 'services.commercial.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.commercial.quote.view.summary',
                component: () => import('@/components/dashboard/commercial/CommercialQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.commercial.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.commercial.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },

    // // MATTERS INDEX
    // {
    //     path: '/services/commercial/matters',
    //     name: 'services.commercial.matters',
    //     component: () => import('@/views/dashboard/commercial/CommercialMattersIndex.vue'),
    //     meta: { 
    //         service: 'commercial',
    //         type: 'matter',
    //         smallMenu: true,
    //         active: 'commercial'
    //     },
    // },
]