import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded relative border p-4 pb-16 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_block_header = _resolveComponent("block-header")!
  const _component_key_value_row = _resolveComponent("key-value-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_block_header, { heading: "Lead Form" }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData.data.form, (item, index) => {
      return (_openBlock(), _createBlock(_component_key_value_row, {
        key: index,
        label: item.label,
        value: item.value
      }, null, 8, ["label", "value"]))
    }), 128))
  ]))
}