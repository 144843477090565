import { InjectionKey } from 'vue'
import { 
    createStore, 
    useStore as baseUseStore, 
    Store, 
    GetterTree, 
    MutationTree, 
    ActionTree 
} from 'vuex'

import PageMetaModule from './PageMetaModule'
export interface Filter {
    id: string;
    open: boolean;
    advanced: boolean;
    paramString: string;
}
export interface ContentHeaderSettings {
    title: string;
    subtext: string;
    filterID: string;
    closeRoute: string;
}

export interface State {
    loggedIn: boolean;
    pageEdited: boolean;
    filters: Filter[];
    permissions: string[];
    contentHeader: ContentHeaderSettings;
    messages: {
        error: string;
        success: string;
    }
}

export const key: InjectionKey<Store<State>> = Symbol();
const state: State = {
    loggedIn: false,
    pageEdited: false,
    filters: [],
    permissions: [],
    contentHeader: {
        title: '',
        subtext: '',
        filterID: '',
        closeRoute: '',
    },
    messages: {
        error: '',
        success: '',
    },
};

/**
 * Getters
 */
const getters: GetterTree<State, any> = {
    authRole: (state) => (permission: string) => {
        return state.permissions.indexOf(permission) != -1;
    },
    getAllPermissions(state) {
        return state.permissions ? state.permissions : [];
    },
    getContentHeader(state) {
        return state.contentHeader as ContentHeaderSettings;
    },
    isLoggedIn: (state) => {
        return state.loggedIn;
    },
    pageEdited: (state) => {
        return state.pageEdited;
    },
    hasError: (state) => {
        return state.messages.error;
    },
    isSuccess: (state) => {
        return state.messages.success;
    }
};


/*
 * Mutations
 * How we mutate our state.
 * Mutations must be synchronous
 */

const mutations: MutationTree<State> = {
    loggedIn(state, payload: boolean)  {
        state.loggedIn = payload;
    },
    addPermission(state, payload: string)  {
        if(state.permissions.indexOf(payload) === -1) state.permissions.push(payload);
    },
    clearPermissions(state) {
        state.permissions = ['nope'];
    },
    updateContentHeader(state, payload: ContentHeaderSettings) {
        state.contentHeader.title = payload.title ? payload.title : '';
        state.contentHeader.subtext = payload.subtext ? payload.subtext : '';
        state.contentHeader.filterID = payload.filterID ? payload.filterID : '';
        state.contentHeader.closeRoute = payload.closeRoute ? payload.closeRoute : '';
    },
    pageEdited(state, payload: boolean) {
        state.pageEdited = payload;
    },
    setError(state, payload: string) {
        state.messages.error = payload;
        setTimeout(() => {
            state.messages.error = '';
        }, 2000);
    },
    setSuccess(state, payload: string) {
        state.messages.success = payload;
        setTimeout(() => {
            state.messages.success = '';
        }, 4000);
    }
};


/*
 * Actions
 * Perform async tasks, then mutate state
 */
const actions: ActionTree<State, any> = {
    loggedIn(store, payload) {
        store.commit('loggedIn', payload);
    },
    updateContentHeader(store, payload) {
        store.commit('updateContentHeader', payload);
    },
    addPermission(store, payload) {
        store.commit('addPermission', payload);
    },
    clearPermissions(store) {
        store.commit('clearPermissions');
    },
    pageEdited(store, payload) {
        store.commit('pageEdited', payload);
    },
    setError(store, payload) {
        store.commit('setError', payload);
    },
    setSuccess(store, payload) {
        store.commit('setSuccess', payload);
    }
};

export const store = createStore({ state, mutations, actions, getters, modules: {
    PageMetaModule: PageMetaModule,
} });

export function useStore() {
    return baseUseStore(key);
}