import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative h-auto" }
const _hoisted_2 = {
  key: 0,
  position: "stacked",
  class: "text-lg block"
}
const _hoisted_3 = {
  key: 1,
  position: "stacked",
  class: "mb-3"
}
const _hoisted_4 = {
  key: 2,
  id: "validation_error",
  class: "absolute -bottom-6 kl-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.header3)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.header3), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_item, {
      lines: "none",
      class: _normalizeClass(["w-full mt-2 mb-5", {'currency-input': _ctx.currency}])
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["class"]),
    (Object.keys(_ctx.error).length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.error[0]), 1))
      : _createCommentVNode("", true)
  ]))
}