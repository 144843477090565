import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  start: "",
  class: "prefix pr-3 border-r absolute top-0 left-0 bottom-0 w-6 flex justify-center items-center text-sm font-bold transition"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.prefix.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.prefix), 1)
      ]))
    : _createCommentVNode("", true)
}