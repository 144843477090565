
<template>
    <div class="rounded relative border p-4 pb-16 mb-6">

        <block-header heading="Quote Details"></block-header>

        <input-wrapper label="First name">
            <ion-input v-model="pageData.data.first_name" size="100%" @IonChange="$emit('changeMade')"></ion-input>
        </input-wrapper>

        <input-wrapper label="Surname">
            <ion-input v-model="pageData.data.surname" size="100%" @IonChange="$emit('changeMade')"></ion-input>
        </input-wrapper>

        <input-wrapper label="Email">
            <ion-input v-model="pageData.data.email" size="100%" @IonChange="$emit('changeMade')" ></ion-input>
        </input-wrapper>

        <input-wrapper label="Phone">
            <ion-input v-model="pageData.data.phone" size="100%" @IonChange="$emit('changeMade')"></ion-input>
        </input-wrapper>

    </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue'
import { 
    IonInput,
} from '@ionic/vue'
import BlockHeader from '@/components/layout/BlockHeader.vue'

export default defineComponent({
    name: 'GenericQuoteDetailsBlock',
    components: {
        BlockHeader,
        IonInput,
    },
    setup() {
        const pageData = inject('data') as any;

        return {
            pageData,
        }
    },
})
</script>
