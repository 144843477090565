<template>
    <h2 class="border-b flex-grow w-full pb-3 mb-4 relative m-0">
        {{heading}}
        <router-link 
            :v-if="router"
            class="absolute right-0 top-1 bottom-0 underline text-xs"
            :to="{name: to}"
        >{{label}}</router-link>

        <slot></slot>

    </h2>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BlockHeader',
    props: {
        heading: {
            type: String,
            default: 'NO HEADING PROVIDED'
        },
        router: {
            type: Boolean,
            default: false,
        },
        to: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    }
})
</script>
