export default [
    {
        path: '/services/probate',
        name: 'services.probate',
        component: () => import('@/views/dashboard/probate/ProbateSummary.vue'),
        meta: { 
            service: 'probate',
            smallMenu: true,
            active: 'probate'
        },
    },

    // LEADS INDEX
    {
        path: '/services/probate/leads',
        name: 'services.probate.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: { 
            service: 'probate',
            type: 'lead',
            smallMenu: true,
            active: 'probate'
        },
    },
    // LEADS SINGLE
    {
        path: '/services/probate/leads/view/:reference?',
        name: 'services.probate.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'probate',
            type: 'lead',
            smallMenu: true,
            active: 'probate',
        }
    },

    // QUOTES INDEX
    {
        path: '/services/probate/quotes',
        name: 'services.probate.quotes',
        component: () => import('@/views/dashboard/probate/ProbateQuotesIndex.vue'),
        meta: { 
            service: 'probate',
            type: 'quote',
            smallMenu: true,
            active: 'probate'
        },
    },
    // QUOTES SINGLE
    {
        path: '/services/probate/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'probate',
            type: 'quote',
            smallMenu: true,
            active: 'probate'
        },
        children: [
            {
                path: '',
                name: 'services.probate.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.probate.quote.view.summary',
                component: () => import('@/components/dashboard/probate/ProbateQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.probate.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.probate.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },

    // // MATTERS INDEX
    // {
    //     path: '/services/probate/matters',
    //     name: 'services.probate.matters',
    //     component: () => import('@/views/dashboard/probate/ProbateMattersIndex.vue'),
    //     meta: { 
    //         service: 'probate',
    //         type: 'matter',
    //         smallMenu: true,
    //         active: 'probate'
    //     },
    // },
]