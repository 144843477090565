export default [
    {
        path: '/companies',
        name: 'companies',
        component: () => import('@/views/dashboard/companies/CompanySummary.vue'),
        meta: { 
            service: 'companies',
            smallMenu: true,
            active: 'companies',
        },
    },

    {
        path: '/companies/introducers',
        name: 'companies.introducers',
        component: () => import('@/views/dashboard/companies/IntroducerCompanyIndex.vue'),
        meta: { 
            service: 'introducers',
            smallMenu: true,
            active: 'companies',
        },
    },
]