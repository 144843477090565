import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row flex-nowrap py-3 text-sm border-b" }
const _hoisted_2 = { class: "w-1/3" }
const _hoisted_3 = { class: "w-2/3 font-bold" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formatLabel(_ctx.label)), 1),
    _createElementVNode("div", _hoisted_3, [
      (typeof _ctx.value === 'string' || typeof _ctx.value === 'number')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.value), 1)
          ], 64))
        : _createCommentVNode("", true),
      (typeof _ctx.value === 'object')
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item, key) => {
              return (_openBlock(), _createElementBlock("li", {
                key: key,
                innerHTML: _ctx.formatItem(item)
              }, null, 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (typeof _ctx.value === 'array')
        ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item.name), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (typeof _ctx.value === 'boolean')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            _createTextVNode(_toDisplayString((_ctx.value == true ? 'yes' : 'no')), 1)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}