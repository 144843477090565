export default [
    // LEADS INDEX
    {
        path: '/conveyancing/residential/transfers/leads',
        name: 'residential.transfers.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: {
            service: 'res-transfer',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },

    // LEADS SINGLE
    {
        path: '/conveyancing/residential/transfers/leads/view/:reference?',
        name: 'residential.transfers.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'res-transfer',
            type: 'lead',
            smallMenu: true,
            active: 'residential',
        }
    },

    // QUOTES INDEX
    {
        path: '/conveyancing/residential/transfers/quotes',
        name: 'residential.transfers.quotes',
        component: () => import('@/views/dashboard/residential/ResidentialTransfersQuotes.vue'),
        meta: {
            service: 'res-transfer',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
    },
    // QUOTES SINGLE
    {
        path: '/conveyancing/residential/transfer/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: {
            service: 'res-transfer',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
        children: [
            {
                path: '',
                name: 'residential.transfer.quotes.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'residential.transfer.quotes.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'documents',
                name: 'residential.transfer.quotes.view.documents',
                component: () => import('@/components/dashboard/misc/QuoteMatterDocuments.vue'),
                props: true,
            },
            {
                path: 'submission',
                name: 'residential.transfer.quotes.view.submission',
                component: () => import('@/components/dashboard/misc/QuoteMatterOriginalSubmission.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'residential.transfer.quotes.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ]
    },
        // // QUOTE TASKS VIEW
        // {
        //     path: '/conveyancing/residential/transfer/quotes/view/:reference?/tasks',
        //     component: () => import('@/views/dashboard/residential/transfer/ResidentialTransferSingleTasks.vue'),
        //     name: 'residential.transfer.quotes.view.tasks',
        //     meta: {
        //         service: 'res-transfer',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE FEES VIEW
        // {
        //     path: '/conveyancing/residential/transfer/quotes/view/:reference?/fees',
        //     component: () => import('@/views/dashboard/residential/transfer/ResidentialTransferSingleFees.vue'),
        //     name: 'residential.transfer.quotes.view.fees',
        //     meta: {
        //         service: 'res-transfer',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE DOCUMENTS VIEW
        // {
        //     path: '/conveyancing/residential/transfer/quotes/view/:reference?/documents',
        //     component: () => import('@/views/dashboard/residential/transfer/ResidentialTransferSingleDocuments.vue'),
        //     name: 'residential.transfer.quotes.view.documents',
        //     meta: {
        //         service: 'res-transfer',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE SUBMISSION VIEW
        // {
        //     path: '/conveyancing/residential/transfer/quotes/view/:reference?/submission',
        //     component: () => import('@/views/dashboard/residential/transfer/ResidentialTransferSingleSubmission.vue'),
        //     name: 'residential.transfer.quotes.view.submission',
        //     meta: {
        //         service: 'res-transfer',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE ACTIVITY VIEW
        // {
        //     path: '/conveyancing/residential/transfer/quotes/view/:reference?/activity',
        //     component: () => import('@/views/dashboard/residential/transfer/ResidentialTransferSingleActivity.vue'),
        //     name: 'residential.transfer.quotes.view.activity',
        //     meta: {
        //         service: 'res-transfer',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
    
    // MATTERS INDEX
    {
        path: '/conveyancing/residential/transfers/matters',
        name: 'residential.transfers.matters',
        component: () => import('@/views/dashboard/residential/ResidentialTransfersMatters.vue'),
        meta: {
            service: 'res-transfer',
            type: 'matter',
            smallMenu: true,
            active: 'residential'
        },
    },
]