export default [
    // LEADS INDEX
    {
        path: '/conveyancing/residential/remortgages/leads',
        name: 'residential.remortgages.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: {
            service: 'res-remortgage',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },

    // LEADS SINGLE
    {
        path: '/conveyancing/residential/remortgages/leads/view/:reference?',
        name: 'residential.remortgages.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'res-remortgage',
            type: 'lead',
            smallMenu: true,
            active: 'residential',
        }
    },

    // QUOTES INDEX
    {
        path: '/conveyancing/residential/remortgages/quotes',
        name: 'residential.remortgages.quotes',
        component: () => import('@/views/dashboard/residential/ResidentialRemortgagesQuotes.vue'),
        meta: {
            service: 'res-remortgage',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
    },

    // QUOTES SINGLE
    {
        path: '/conveyancing/residential/remortgage/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: {
            service: 'res-remortgage',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
        children: [
            {
                path: '',
                name: 'residential.remortgage.quotes.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'residential.remortgage.quotes.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'documents',
                name: 'residential.remortgage.quotes.view.documents',
                component: () => import('@/components/dashboard/misc/QuoteMatterDocuments.vue'),
                props: true,
            },
            {
                path: 'submission',
                name: 'residential.remortgage.quotes.view.submission',
                component: () => import('@/components/dashboard/misc/QuoteMatterOriginalSubmission.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'residential.remortgage.quotes.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ]
    },
        // // QUOTE TASKS VIEW
        // {
        //     path: '/conveyancing/residential/remortgage/quotes/view/:reference?/tasks',
        //     component: () => import('@/views/dashboard/residential/remortgage/ResidentialRemortgageSingleTasks.vue'),
        //     name: 'residential.remortgage.quotes.view.tasks',
        //     meta: {
        //         service: 'res-remortgage',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE FEES VIEW
        // {
        //     path: '/conveyancing/residential/remortgage/quotes/view/:reference?/fees',
        //     component: () => import('@/views/dashboard/residential/remortgage/ResidentialRemortgageSingleFees.vue'),
        //     name: 'residential.remortgage.quotes.view.fees',
        //     meta: {
        //         service: 'res-remortgage',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE DOCUMENT VIEW
        // {
        //     path: '/conveyancing/residential/remortgage/quotes/view/:reference?/documents',
        //     component: () => import('@/views/dashboard/residential/remortgage/ResidentialRemortgageSingleDocuments.vue'),
        //     name: 'residential.remortgage.quotes.view.documents',
        //     meta: {
        //         service: 'res-remortgage',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE SUBMISSION
        // {
        //     path: '/conveyancing/residential/remortgage/quotes/view/:reference?/submission',
        //     component: () => import('@/views/dashboard/residential/remortgage/ResidentialRemortgageSingleSubmission.vue'),
        //     name: 'residential.remortgage.quotes.view.submission',
        //     meta: {
        //         service: 'res-remortgage',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },
        // // QUOTE ACTIVITY
        // {
        //     path: '/conveyancing/residential/remortgage/quotes/view/:reference?/activity',
        //     component: () => import('@/views/dashboard/residential/remortgage/ResidentialRemortgageSingleActivity.vue'),
        //     name: 'residential.remortgage.quotes.view.activity',
        //     meta: {
        //         service: 'res-remortgage',
        //         type: 'quote',
        //         smallMenu: true,
        //         active: 'residential'
        //     },
        // },

    // MATTERS INDEX
    {
        path: '/conveyancing/residential/remortgages/matters',
        name: 'residential.remortgages.matters',
        component: () => import('@/views/dashboard/residential/ResidentialRemortgagesMatters.vue'),
        meta: {
            service: 'res-remortgage',
            type: 'matter',
            smallMenu: true,
            active: 'residential'
        },
    },
]