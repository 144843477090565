import { Module } from "vuex";

const PageMetaModule: Module<PageMeta, any> = {
    state: {
        metaTitle: 'Law Manager',
        metaDescription: 'Keep track of your legal documents',
    },

    mutations: {
        setMetaTitle(state, payload: string) {
            state.metaTitle = payload;
        },
        setMetaDescription(state, payload: string) {
            state.metaDescription = payload;
        }
    },

    actions: {
        setMetaTitle(store, payload: string) {
            store.commit('setMetaTitle', payload);
        },
        setMetaDescription(store, payload: string) {
            store.commit('metaDescription', payload);
        }
    },

    getters: {
        getMetaTitle(state): string {
            return state.metaTitle;
        },
        getMetaDescription(state): string {
            return state.metaDescription;
        },
    }
}

export interface PageMeta {
    metaTitle: string;
    metaDescription: string;
}

export default PageMetaModule;