export default [
    // LEADS INDEX
    {
        path: '/conveyancing/residential/lease/leads',
        name: 'residential.lease.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: {
            service: 'res-lease',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },
    // LEADS SINGLE
    {
        path: '/conveyancing/residential/lease/leads/view/:reference?',
        name: 'residential.lease.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'res-lease',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },

    // QUOTES INDEX
    {
        path: '/conveyancing/residential/lease/quotes',
        name: 'residential.lease.quotes',
        component: () => import('@/views/dashboard/residential/lease/ResidentialLeaseQuotesIndex.vue'),
        meta: {
            service: 'res-lease',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
    },

    // QUOTES SINGLE
    {
        path: '/conveyancing/residential/lease/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'res-lease',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
        children: [
            {
                path: '',
                name: 'services.lease.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.lease.quote.view.summary',
                component: () => import('@/components/dashboard/residential/lease/ResidentialLeaseQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.lease.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.lease.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },
];