import app from './main';

import ResidentialSidebar from '@/components/dashboard/residential/ResidentialSidebar.vue';
import CommercialSidebar from '@/components/dashboard/commercial/CommercialSidebar.vue';
import LpaSidebar from '@/components/dashboard/lpa/LpaSidebar.vue';
import ProbateSidebar from '@/components/dashboard/probate/ProbateSidebar.vue';
import WillSidebar from '@/components/dashboard/wills/WillSidebar.vue';
import FamilySidebar from '@/components/dashboard/family/FamilySidebar.vue';
import EmploymentSidebar from '@/components/dashboard/employment/EmploymentSidebar.vue';
import CommercialCorporateSidebar from '@/components/dashboard/commercial-corporate/CommercialCorporateSidebar.vue';
import CourtSidebar from '@/components/dashboard/court/CourtSidebar.vue';
import DisputesSidebar from '@/components/dashboard/disputes/DisputesSidebar.vue';
import OtherServicesSidebar from '@/components/dashboard/otherServices/OtherServicesSidebar.vue';

app.component('residential-sidebar', ResidentialSidebar);
app.component('commercial-sidebar', CommercialSidebar);
app.component('lpa-sidebar', LpaSidebar);
app.component('probate-sidebar', ProbateSidebar);
app.component('will-sidebar', WillSidebar);
app.component('family-sidebar', FamilySidebar);
app.component('employment-sidebar', EmploymentSidebar);
app.component('commercial-corporate-sidebar', CommercialCorporateSidebar);
app.component('court-sidebar', CourtSidebar);
app.component('disputes-sidebar', DisputesSidebar);
app.component('other-services-sidebar', OtherServicesSidebar);


import KewContactsBlock from '@/components/layout/KewContactsBlock.vue';
import IntroducerContactsBlock from '@/components/layout/IntroducerContactsBlock.vue';
import GenericQuoteDetailsBlock from '@/components/layout/GenericQuoteDetailsBlock.vue';
import GenericLeadDetailsBlock from '@/components/layout/GenericLeadDetailsBlock.vue';
import LeadFormBlock from '@/components/layout/LeadFormBlock.vue';
import NotesBlock from '@/components/layout/NotesBlock.vue';

import LoadingButton from './components/utility/LoadingButton.vue';
import InputWrapper from '@/components/forms/InputWrapper.vue';
import InputPrefix from '@/components/forms/InputPrefix.vue';
import ActionBar from '@/components/footer/ActionBar.vue';
import BlockHeader from '@/components/layout/BlockHeader.vue'
import TwoColumn from '@/components/layout/TwoColumn.vue';
import LoadingInner from '@/components/utility/LoadingInner.vue';

app.component('loading-button', LoadingButton);
app.component('kew-contacts-block', KewContactsBlock);
app.component('introducer-contacts-block', IntroducerContactsBlock);
app.component('notes-block', NotesBlock);
app.component('generic-quote-details-block', GenericQuoteDetailsBlock);
app.component('generic-lead-details-block', GenericLeadDetailsBlock);
app.component('lead-form-block', LeadFormBlock);
app.component('input-wrapper', InputWrapper);
app.component('input-prefix', InputPrefix);
app.component('action-bar', ActionBar);
app.component('block-header', BlockHeader);
app.component('two-column', TwoColumn);
app.component('loading-inner', LoadingInner);