<template>
    <div class="h-full border-r overflow-y-scroll">

        <sub-menu
            :links="sales"
            header="Res. Sales"
        ></sub-menu>

        <sub-menu
            :links="purchase"
            header="Res. Purchase"
        ></sub-menu>

        <sub-menu
            :links="sale_purchase"
            header="Res. Sales & Purchases"
        ></sub-menu>

        <sub-menu
            :links="remortgages"
            header="Res. Remortages"
        ></sub-menu>

        <sub-menu
            :links="transfers"
            header="Res. Transfers"
        ></sub-menu>

        <!-- <sub-menu
            :links="equity"
            header="Equity Release"
        ></sub-menu>

        <sub-menu
            :links="legal_advice"
            header="Independent Legal Advice"
        ></sub-menu>

        <sub-menu
            :links="lease"
            header="Lease Extension"
        ></sub-menu>

        <sub-menu
            :links="htb"
            header="Help To Buy"
        ></sub-menu>

        <sub-menu
            :links="general"
            header="General"
        ></sub-menu> -->

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SubMenu from '@/components/menu/SubMenu.vue'
import menus from '@/utils/menus'

export default defineComponent({
    name: 'ResidentialSidebar',
    components: {
        SubMenu
    },
    setup() {
        const { 
            residentialSaleSidebarLinks, 
            residentialPurchaseSidebarLinks, 
            residentialRemortgageSidebarLinks,
            residentialTransferSidebarLinks,
            residentialSalePurchaseSidebarLinks,
            residentialEquitySidebarLinks,
            residentialLegalAdviceSidebarLinks,
            residentialLeaseSidebarLinks,
            residentialHtbSidebarLinks,
            residentialGeneralSidebarLinks,
        } = menus();
        return {
            sales: residentialSaleSidebarLinks,
            purchase: residentialPurchaseSidebarLinks,
            remortgages: residentialRemortgageSidebarLinks,
            transfers: residentialTransferSidebarLinks,
            sale_purchase: residentialSalePurchaseSidebarLinks,
            equity: residentialEquitySidebarLinks,
            legal_advice: residentialLegalAdviceSidebarLinks,
            lease: residentialLeaseSidebarLinks,
            htb: residentialHtbSidebarLinks,
            general: residentialGeneralSidebarLinks,
        }
    }    
})
</script>