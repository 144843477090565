export default [
    // LEADS INDEX
    {
        path: '/conveyancing/residential/htb/leads',
        name: 'residential.htb.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: {
            service: 'res-htb',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },
    // LEADS SINGLE
    {
        path: '/conveyancing/residential/htb/leads/view/:reference?',
        name: 'residential.htb.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'res-htb',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    },

    // QUOTES INDEX
    {
        path: '/conveyancing/residential/htb/quotes',
        name: 'residential.htb.quotes',
        component: () => import('@/views/dashboard/residential/htb/ResidentialHtbQuotesIndex.vue'),
        meta: {
            service: 'res-htb',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
    },

    // QUOTES SINGLE
    {
        path: '/conveyancing/residential/htb/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'res-htb',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
        children: [
            {
                path: '',
                name: 'services.htb.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.htb.quote.view.summary',
                component: () => import('@/components/dashboard/residential/htb/ResidentialHtbQuoteSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.htb.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.htb.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },
];