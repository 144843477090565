import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-b flex-grow w-full pb-3 mb-4 relative m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("h2", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.heading) + " ", 1),
    _createVNode(_component_router_link, {
      "v-if": _ctx.router,
      class: "absolute right-0 top-1 bottom-0 underline text-xs",
      to: {name: _ctx.to}
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }, 8, ["v-if", "to"]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}