<template>
    <div class="h-full border-r overflow-y-scroll">

        <sub-menu
            :links="links"
            header="Commercial Property"
        ></sub-menu>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SubMenu from '@/components/menu/SubMenu.vue'
import menus from '@/utils/menus'

export default defineComponent({
    name: 'CommercialSidebar',
    components: {
        SubMenu
    },
    setup() {
        const { commercialPropertySidebarLinks } = menus();

        return {
            links: commercialPropertySidebarLinks,
        }
    }  
})
</script>