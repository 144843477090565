<template>
    <div class="bg-white p-3 z-10" 
    :class="{'action-bar': shadow}">
        <div class="flex flex-row">
            <div class="mr-auto">
                <slot name="left"></slot>
            </div>
            <div class="ml-auto">
                <slot name="right"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'ActionBar',
    props: {
        shadow: {
            type: Boolean,
            default: false,
        }
    }
})
</script>

<style>
.action-bar {
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}
</style>