<template>
    <div class="rounded relative border p-4 pb-16 mb-6">

        <block-header heading="Introducer"></block-header>

        <input-wrapper label="Company">
            <ion-select
                placeholder="Select Company"
                @ionChange="filterBranches()"
                :value="pageData.data.introducer_company_id"
                v-model="pageData.data.introducer_company_id" 
            >
                <ion-select-option value="">None</ion-select-option>
                <ion-select-option 
                    v-for="(company,index) in pageData.introducer_companies"
                    :key="index"
                    :value="company.id"
                >{{company.name}}</ion-select-option>
            </ion-select>
        </input-wrapper>

        <input-wrapper label="Branch" :error="pageData.errors.introducer_branch_id">
            <ion-select
                :disabled="!pageData.data.introducer_company_id"
                placeholder="Select Branch"
                @ionChange="filterUsers()"
                :value="pageData.data.introducer_branch_id"
                v-model="pageData.data.introducer_branch_id" 
            >
                <ion-select-option value="">None</ion-select-option>
                <ion-select-option 
                    v-for="(branch,index) in displayData.introducerBranches"
                    :key="index"
                    :value="branch.id"
                >{{branch.name}}</ion-select-option>
            </ion-select>
        </input-wrapper>

        <input-wrapper label="Assigned User" :error="pageData.errors.introducer_id">
            <ion-select
                :disabled="!pageData.data.introducer_branch_id"
                placeholder="Select User"
                @ionChange="$emit('changeMade')"
                :value="pageData.data.introducer_id"
                v-model="pageData.data.introducer_id" 
            >
                <ion-select-option value="">None</ion-select-option>
                <ion-select-option 
                    v-for="(user,index) in displayData.introducerUsers"
                    :key="index"
                    :value="user.id"
                >{{user.full_name + (user.type === 'introducer-admin'? ' - Admin': '')}}</ion-select-option>
            </ion-select>
        </input-wrapper>

    </div>
</template>

<script setup lang="ts">
import { inject, onMounted, reactive, watch } from 'vue'
import { 
    IonSelect,
    IonSelectOption,
} from '@ionic/vue'
import BlockHeader from '@/components/layout/BlockHeader.vue'
import { Branch } from '@/interfaces/types/BranchInterfaces';
import { User } from '@/interfaces/types/UserInterfaces';

const pageData = inject('data') as any;

const emit = defineEmits(
    [
        'changeMade',
    ]
)
const displayData = reactive({
    introducerBranches: [] as Branch[],
    introducerUsers: [] as User[],
});

function filterBranches() {
    displayData.introducerBranches = pageData.introducer_branches.filter((branch: Branch) => {
        return branch.company_id === pageData.data.introducer_company_id;
    });
}

function filterUsers() {
    displayData.introducerUsers = pageData.introducer_users.filter((user: User) => {
        return user.branches.includes(pageData.data.introducer_branch_id)
            || ( user.company_id === pageData.data.introducer_company_id && user.type === 'introducer-admin');
    });
}
watch(() => [pageData.data.introducer_company_id,
            pageData.data.introducer_branch_id,
            pageData.data.introducer_id], () => {
            emit('changeMade');
});

onMounted(() => {
    if(pageData.data.introducer_company_id) {
        filterBranches();
        filterUsers();
    }
});

</script>
