import { AxiosKey } from "@/symbols"
import { AxiosError, AxiosResponse } from "axios";
import { injectStrict } from "./injectTyped"
import { computed, reactive } from "vue";

/* eslint-disable no-useless-escape */

export default function() {

    const axios = injectStrict(AxiosKey);

    const managers = computed(() => data.managers);
    const branches = computed(() => data.branches);
    const data = reactive({
        managers: [] as any[],
        branches: [] as any[],
    })

    const getManagers = () => {
        axios.get('/api/branches/managers/get')
       .then((response: AxiosResponse) => {
           console.log(response.data);
           data.managers = response.data as any[];
       })
       .catch( (e: AxiosError) => {
           console.log(e);
       })
   }

   const getBranches = async() => {
     await axios.get('/api/branches')
    .then((response: AxiosResponse) => {
        console.log(response.data);
        data.branches = response.data as any[];
        return branches;
    })
    .catch( (e: AxiosError) => {
        console.log(e);
    })
}

    const getPermissionsFromService = ( $service : string ) => {
        switch ($service) {
            case 'res-sale': return 'residential';
            case 'res-purchase': return 'residential';
            case 'res-remortgage': return 'residential';
            case 'res-transfer': return 'residential';
            case 'res-sale-purchase': return 'residential';
            case 'res-equity': return 'residential';
            case 'res-legal-advice': return 'residential';
            case 'res-htb': return 'residential';
            case 'res-lease': return 'residential';
            case 'res-general': return 'residential';
            case 'commercial': return 'commercial';
            case 'lpa' : return 'lpas';
            case 'probate': return 'probate';
            case 'wills': return 'wills';
            case 'family': return 'family';
            case 'employment': return 'employment';
            case 'employment-settlement': return 'employment';
            case 'commercial-corporate': return 'commercial-corporate';
            case 'court': return 'protection-court';
            case 'disputes': return 'disputes';
            case 'other': return 'other';
            default: return $service;
        }
    }

    const getServiceFromType = ( $type : string ) => {
        switch ($type) {
            case "App\\Models\\ResSaleQuote": return 'res-sale';
            case "App\\Models\\ResPurchaseQuote": return 'res-purchase';
            case "App\\Models\\ResRemortgageQuote": return 'res-remortgage';
            case "App\\Models\\ResTransferQuote": return 'res-transfer';
            case "App\\Models\\ResSalePurchaseQuote": return 'res-sale-purchase';
            case "App\\Models\\ResidentialEquityQuote": return 'res-equity';
            case "App\\Models\\ResidentialLegalAdviceQuote": return 'res-legal-advice';
            case "App\\Models\\ResidentialHtbQuote": return 'res-htb';
            case "App\\Models\\ResidentialLeaseQuote": return 'res-lease';
            case "App\\Models\\ResidentialGeneralQuote": return 'res-general';
            case "App\\Models\\CommercialQuote": return 'commercial';
            case "App\\Models\\LpaQuote": return 'lpa';
            case "App\\Models\\ProbateQuote": return 'probate';
            case "App\\Models\\WillQuote": return 'wills';
            case "App\\Models\\FamilyQuote": return 'family';
            case "App\\Models\\EmploymentQuote": return 'employment';
            case "App\\Models\\EmploymentSettlementQuote": return 'employment-settlement';
            case "App\\Models\\CommercialCorporateQuote": return 'commercial-corporate';
            case "App\\Models\\CourtProtectionQuote": return 'court';
            case "App\\Models\\DisputesQuote": return 'disputes';
            case "App\\Models\\OtherQuote": return 'other';

            default: return $type;
        }
    }

    return {
        getManagers,
        getBranches,
        getPermissionsFromService,
        getServiceFromType,
    }
}