import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default function() {
    // initiate the store and reactive variables
    const store = useStore();
    const metaTitle = computed(() => store.getters.getMetaTitle);
    const metaDescription = computed(() => store.getters.getMetaDescription);

    // initiatialise the meta description - helps set the variable type
    const descriptionTag = ref(document.querySelector('head meta[name="description"]'));

    const initMeta = () => {
        // reinitialises the meta descriptiong externally if needed
        descriptionTag.value = document.querySelector('head meta[name="description"]');
    }

    // updates the title on state change
    watch(() => metaTitle.value, (newVal) => {  
        document.title = newVal;
    })

    // updates the description on state change
    watch(() => metaDescription.value, (newVal) => {
        if(descriptionTag.value) {
            descriptionTag.value.setAttribute('content', newVal);
        }
    })

    // returns the initialisation function for use elsewhere
    return {
        initMeta,
    }
}