import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import http from '@/http'
import { AxiosKey } from '@/symbols'
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query'

// console.log(process.env.VUE_APP_API_URL);


import { IonicVue } from '@ionic/vue';

import { store } from '@/store/index';

import axios from 'axios';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

import 'tailwindcss/tailwind.css'

import 'tippy.js/dist/tippy.css' // optional for styling

/* Basic CSS for apps built with Ionic */
// import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
// icons
import { 
  faPlus,
  faBold,
  faItalic,
  faStrikethrough,
  faCode,
  faBracketsCurly,
  faHighlighter,
  faSpinner,
  faTimes,
  faH1,
  faH2,
  faParagraph,
  faListUl,
  faListOl,
  faClipboardList,
  faQuoteLeft,
  faHorizontalRule,
  faLevelDownAlt,
  faRemoveFormat,
  faUndo,
  faRedo,
  faSpinnerThird,
  faCity,
  faColumns,
  faCompressAlt,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faEye,
  // faBold,
  // faItalic,
  // faStrikethrough,
  // faCode,
  faList,
  // faListOl,
  faQuoteRight,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faSortUp,
  faSortDown,
  faSave,
  faTimesCircle,
  faPencilAlt,
  faCaretDown,
  faCaretUp,
  faEyeSlash,
  faGripLines,
  faGripLinesVertical,
} from '@fortawesome/free-solid-svg-icons';

import {
  faHome,
  faBuilding,
  faFileContract,
  faGavel,
  faShieldAlt,
  faComments,
  faBriefcase,
  faEnvelopeOpenText,
  faCodeBranch,
  faCog,
  faUsers,
  faFileAlt,
  faLink,
  faTrashAlt,
  faEdit,
  faEnvelope,
  faUmbrella,
  faBooks,
  faQuestionCircle,
  faTable,
  faGripLines as faGripLinesLight,
  faGripLinesVertical as faGripLinesVerticalLight,
} from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faPlus,
  faHome,
  faBuilding,
  faFileContract,
  faGavel,
  faShieldAlt,
  faComments,
  faBriefcase,
  faEnvelopeOpenText,
  faCodeBranch,
  faCog,
  faUsers,
  faFileAlt,
  faLink,
  faEye,
  faEyeSlash,
  faSpinner,
  faTimes,
  faBold,
  faItalic,
  faStrikethrough,
  faCode,
  faList,
  faListOl,
  faQuoteRight,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faSortUp,
  faSortDown,
  faSave,
  faTimesCircle,
  faTrashAlt,
  faEdit,
  faPencilAlt,
  faEnvelope,
  faBracketsCurly,
  faHighlighter,
  faH1,
  faH2,
  faClipboardList,
  faQuoteLeft,
  faHorizontalRule,
  faLevelDownAlt,
  faRemoveFormat,
  faUndo,
  faRedo,
  faParagraph,
  faListUl,
  faSpinnerThird,
  faUmbrella,
  faBooks,
  faQuestionCircle,
  faCity,
  faCaretDown,
  faCaretUp,
  faTable,
  faColumns,
  faGripLines,
  faGripLinesVertical,
  faGripLinesLight,
  faGripLinesVerticalLight,
  faCompressAlt,
)

/* Theme variables */
import './theme/variables.css'
import './theme/styles.css'
import './theme/forms.css'
import './theme/tasks.css'

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: false
  })
  .use(router)
  .use(store)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .component("fa-icon",FontAwesomeIcon)
  .provide(AxiosKey, http);

export default app;
require('./_components');

router.isReady().then(() => {
  app.mount('#app');
});

defineCustomElements(window);