export default [
    // SERVICE OVERVIEW
    {
        path: '/services/wills',
        name: 'services.wills',
        component: () => import('@/views/dashboard/wills/WillSummary.vue'),
        meta: { 
            service: 'wills',
            smallMenu: true,
            active: 'will'
        },
    },

    // LEADS INDEX
    {
        path: '/services/wills/leads',
        name: 'services.wills.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: { 
            service: 'wills',
            type: 'lead',
            smallMenu: true,
            active: 'will'
        },
    },
    // LEADS SINGLE
    {
        path: '/services/wills/leads/view/:reference?',
        name: 'services.wills.leads.view',
        component: () => import('@/views/dashboard/LeadSingle.vue'),
        meta: {
            service: 'wills',
            type: 'lead',
            smallMenu: true,
            active: 'will',
        }
    },

    // QUOTES INDEX
    {
        path: '/services/wills/quotes',
        name: 'services.wills.quotes',
        component: () => import('@/views/dashboard/wills/WillQuotesIndex.vue'),
        meta: { 
            service: 'wills',
            type: 'quote',
            smallMenu: true,
            active: 'will'
        },
    },
    // QUOTES SINGLE
    {
        path: '/services/wills/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: { 
            service: 'wills',
            type: 'quote',
            smallMenu: true,
            active: 'will'
        },
        children: [
            {
                path: '',
                name: 'services.wills.quote.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'summary',
                name: 'services.wills.quote.view.summary',
                component: () => import('@/components/dashboard/wills/WillSummary.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.wills.quote.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.wills.quote.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ],
    },

    // MATTERS INDEX
    {
        path: '/services/wills/matters',
        name: 'services.wills.matters',
        component: () => import('@/views/dashboard/wills/WillMattersIndex.vue'),
        meta: { 
            service: 'wills',
            type: 'matter',
            smallMenu: true,
            active: 'will'
        },
    },
]